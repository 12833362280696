<template>
  <div>
    <el-main>
      <!-- 表单 -->
      <el-form class="el-form-search" label-width="110px">
        <el-form-item label="用户账号：">
          <el-input size="small" placeholder="请输入用户账号" v-model="searchForm.u_mobile" ></el-input>
        </el-form-item>
        <el-form-item label="技工姓名：">
          <el-input size="small" placeholder="请输入技工姓名" v-model="searchForm.artisan_name"></el-input>
        </el-form-item>
        <el-form-item label="联系电话：">
          <el-input size="small" placeholder="请输入联系电话" v-model="searchForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="审核状态：">
          <el-select v-model="searchForm.status" size="small" placeholder="请选择" clearable>
          <el-option label="全部" :value="0"></el-option>
          <el-option label="待审核" :value="-1"></el-option>
          <el-option label="已申退" :value="-2"></el-option>
          <el-option label="已通过" :value="1"></el-option>
          <el-option label="已驳回" :value="2"></el-option>
        </el-select>
      </el-form-item>
        <el-form-item label="申请时间：">
          <el-date-picker size="small" v-model="searchForm.start_time" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item label=" " label-width="25px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
      </el-form>
      <!-- 列表 -->
      <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" :key="key">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="u_mobile" label="用户账号" align="center"></el-table-column>
        <el-table-column prop="artisan_name" label="技工姓名" align="center"></el-table-column>
        <el-table-column prop="mobile" label="联系电话" align="center"></el-table-column>
        <el-table-column prop="id_number" label="身份证号码" align="center"></el-table-column>
        <el-table-column prop="type" label="性别" align="center" width="80">
          <template v-slot="{ row }">
            <span>
              {{ row.sex == 0 ? '女' : row.sex == 1 ? '男' : '--' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="所在地址" align="center"></el-table-column>
        <el-table-column prop="job_title" label="职称" align="center"></el-table-column>
        <el-table-column prop="years_of_working" label="工作年限" align="center"></el-table-column>
        <el-table-column prop="store_picture" label="资质证书" width="100" align="center">
        <template v-slot="{ row }">
          <div class="demo-image__preview">
            <el-image style="width: 100px; height: 50px" :src="row.certification[0]" :preview-src-list="row.certification"></el-image>
          </div>
        </template>
      </el-table-column>
        <el-table-column prop="avatar" label="个人头像" align="center">
          <template v-slot="{ row }">
            <el-image style="width: 50px; height: 50px; border-radius: 50%" :src="row.avatar"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="审核状态" align="center">
          <template v-slot="{ row }">
            <span>
              {{ row.status == -1 ? '待审核' : row.status == -2 ? '已申退' : row.status == 1 ? '已通过' : '已驳回' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="申请时间" width="200" align="center">
          <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
        </el-table-column>
        <el-table-column label="操作" width="160" align="center" fixed="right">
          <template v-slot="{ row }">
            <div class="btn" v-if="row.status == -1">
              <el-button @click="review(row, 1)" type="text" size="small">通过</el-button>
              <el-button @click="review(row, 2)" type="text" size="small">驳回</el-button>
            </div>
            <el-button v-if="row.status == 2" @click="(remark = row.remark), (showRemark = !0)" type="text" size="small">驳回原因</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 翻页 -->
      <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    </el-main>
    <el-dialog title="审核" :visible.sync="showReview" width="600px">
      <el-form label-width="140px">
        <el-form-item label="审核结果：" required="true">
          <el-radio-group v-model="status">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="2">驳回</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="结算费率：" style="width: 500px" v-if="status == 1" required="true">
          <el-input v-model="rate" type="number" min="0.00" max="100.00" step="0.01" ><template slot="append">%</template></el-input>
        </el-form-item>
        <el-form-item label="驳回原因：" v-if="status == 2" required="true">
          <el-input type="textarea" :rows="4" v-model="remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showReview = !1">取 消</el-button>
        <el-button type="primary" @click="comfirmReview">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="驳回原因" :visible.sync="showRemark" width="600px">
      {{ remark }}
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showRemark = !1">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import _ from 'lodash';
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      list: [],
      total_number: 0,
      searchForm: {
        page: 1,
        rows: 10,
        u_mobile: '',
        artisan_name: '',
        mobile: '',
        status: 0,
        start_time: '',
        end_time: '',
      },
      showReview: !1,
      showRemark: !1,
      id: '',
      status: 2,
      rate: 0,
      remark: '',
    };
  },
  created() {
    this.getList();
  },
  methods: {
    search() {
      this.page = 1;
      this.getList();
    },
    clearSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        u_mobile: '',
        store_name: '',
        merchant_name: '',
        merchant_mobile: '',
        province_id: '',
        city_id: '',
        area_id: [],
        status: 0,
        start_time: '',
        end_time: '',
      };
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
        this.getList();
      } else {
        this.searchForm.page = val;
        this.getList();
      }
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.start_time) {
        searchForm.end_time = searchForm.start_time[1] / 1000;
        searchForm.start_time = searchForm.start_time[0] / 1000;
      }
      this.$axios.post(this.$api.repair.artisan.auditList, searchForm).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          for (let i in list) {
            list[i].certification = [list[i].certification];
          }
          this.list = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    review(row, status) {
      this.status = status;
      this.id = row.id;
      this.remark = '';
      this.rate = 80;
      this.showReview = !0;
    },
    comfirmReview() {
      if (!this.rate && this.status == 1) return this.$message.warning('请添加结算费率');
      if (!this.remark && this.status == 2) return this.$message.warning('请添加驳回原因');
      this.$axios.post(this.$api.repair.artisan.auditStatus, {
          id: this.id,
          status: this.status,
          rate: this.rate,
          remark: this.remark,
        })
        .then(res => {
          if (res.code == 0) {
            this.showReview = !1;
            this.$message.success('处理成功');
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
      },
    },
};
</script>
<style lang="less" scoped>

.el-main {
  background: #fff;
}
</style>
